import React from 'react'

import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL, isPropertyNullOrEmpty } from 'Helpers/utilities'

const RatesCardBlock = ({ data, inEditMode, isActive }) => {
  const {
    Image,
    Heading,
    AnnualPercentageRateValue,
    AnnualPercentageRate,
    AnnualPercentageRateDetail,
    TermValueBigFont,
    TermValueSmallFont,
    TermValueSmallFontPosition,
    Term,
    TermDetail,
    Url
  } = data

  if (!Heading && !Image && !inEditMode) return null
  const Link = Url.Value[0]
  const cardSidePadding = 'px-6'

  const populateSpace = '---'
  const isTermNull = isPropertyNullOrEmpty(Term)
  const isTermDetailNull = isPropertyNullOrEmpty(TermDetail)

  return (
    <div className={`h-[253px] w-[331px] shadow-normal 
      rounded-lg bg-white md:flex-1 flex flex-col justify-between`}>
      <div className={`${cardSidePadding} flex items-center justify-center gap-3 py-2 border-b border-gray-200`}>
        {Image?.Url && (
          <div data-epi-edit="Image" className="w-[36px] h-[36px] bg-[#F3E8FF] rounded-full flex items-center justify-center">
            <img
              data-src={addWebPToImageURL(Image?.Url)}
              alt={Image?.AltTag}
              className="lazy w-[24px] h-[24px]"
            />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
        )}
        <h3 data-epi-edit="Heading" className="text-[30px] font-semibold leading-[60px] text-violet">
          {Heading}
        </h3>
      </div>
      <div className={`${cardSidePadding} flex justify-center items-center gap-2`}>
        {/* Left Side */}
        <div className="flex flex-col gap-2 w-full">
          <div className="flex items-center justify-center">
            <span
              className="text-[15px] text-center font-bold leading-[27px] italic text-[#1F2937] translate-y-[5px]"
              data-epi-edit="AnnualPercentageRateDetail">
              {AnnualPercentageRateDetail}
            </span>
          </div>
          <div className="flex items-center justify-center">
            <span className="text-[32px] font-bold leading-[27px] text-[#1F2937]" data-epi-edit="AnnualPercentageRateValue">
              {AnnualPercentageRateValue}
            </span>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center h-[24px] bg-[#F3E8FF] rounded-full px-2 w-full">
              <span
                className="text-[16px] font-bold leading-6 tracking-[0.07em] text-violet"
                data-epi-edit="AnnualPercentageRate">
                {AnnualPercentageRate}
              </span>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="flex flex-col gap-2 text-right w-full">
          <div className="flex items-center justify-center">
            <span
              className={`text-[15px] text-center font-bold leading-[27px] italic text-[#1F2937] translate-y-[5px] ${isTermDetailNull && 'invisible'}`}
              data-epi-edit="TermDetail">
              {TermDetail || populateSpace}
            </span>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex items-center">
              {TermValueSmallFontPosition === 'left' && (
                <span
                  className="text-[15px] font-bold leading-[27px] italic text-[#1F2937] mr-1 translate-y-[5px]"
                  data-epi-edit="TermValueSmallFont">
                  {TermValueSmallFont}
                </span>
              )}
              <span
                className="text-[32px] font-bold leading-[27px] text-[#1F2937]"
                data-epi-edit="TermValueBigFont">
                {TermValueBigFont}
              </span>
              {TermValueSmallFontPosition === 'right' && (
                <span
                  className="text-[15px] font-bold leading-[27px] italic text-[#1F2937] ml-1 translate-y-[5px]"
                  data-epi-edit="TermValueSmallFont">
                  {TermValueSmallFont}
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className={`flex items-center justify-center h-[24px] bg-[#F3E8FF] rounded-full px-2 w-full ${isTermNull && 'invisible'}`}>
              <span
                className="text-[16px] font-bold leading-6 tracking-[0.07em] text-violet"
                data-epi-edit="Term">
                {Term}
              </span>
            </div>
          </div>
        </div>
      </div>
      {Link && (
        <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode} className={`block w-full border-t border-gray-200 ${cardSidePadding} py-4`}>
          <div data-epi-edit="Url" className="text-violet hover:text-violet-light flex items-center justify-center gap-2 font-medium text-[17px] w-full">
            {Link.Text}
            <svg className='text-[#883ED2]' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M3 10C3 9.58579 3.33579 9.25 3.75 9.25L14.3879 9.25L10.2302 5.29062C9.93159 5.00353 9.92228 4.52875 10.2094 4.23017C10.4965 3.93159 10.9713 3.92228 11.2698 4.20937L16.7698 9.45937C16.9169 9.60078 17 9.79599 17 10C17 10.204 16.9169 10.3992 16.7698 10.5406L11.2698 15.7906C10.9713 16.0777 10.4965 16.0684 10.2094 15.7698C9.92228 15.4713 9.93159 14.9965 10.2302 14.7094L14.3879 10.75L3.75 10.75C3.33579 10.75 3 10.4142 3 10Z" fill="currentColor" />
            </svg>
          </div>
        </RouterLinkTemplate>
      )}
    </div>
  )
}

export default React.memo(RatesCardBlock)
