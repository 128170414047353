import React from 'react'

const DisclosureContentBlock = ({ data, inEditMode }) => {
  const { Content } = data
  if (!Content && !inEditMode) return null

  const markdown = { __html: Content }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                   lg:pt-12 lg:pb-8 md:py-7 py-4">
        <div data-epi-edit="Content" className="font-body text-gray-600 text-xs" dangerouslySetInnerHTML={markdown} />
      </div>
    </div>
  )
}

export default React.memo(DisclosureContentBlock)
