import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function useLocationPathChange(action) {
  const location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      if (location.hash) {
        // Find element by hash ID (removing '#' from hash string)
        const element = document.getElementById(location.hash.slice(1))
        if (element) {
          // Dispatch hashchange event for HeaderBlock to handle scroll adjustment
          // This accounts for sticky header offset
          window.dispatchEvent(new Event('hashchange'))
        }
      } else {
        // No hash present, scroll to top of page
        window.scrollTo({ top: 0, behavior: 'instant' })
      }
    }

    // Execute scroll handler if document is already interactive/complete
    if (document.readyState !== 'loading') {
      handleScroll()
    }

    // Backup event listener for dynamically loaded content
    window.addEventListener('load', handleScroll)

    // Execute provided action callback with current pathname
    if (action) {
      action(location.pathname)
    }

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('load', handleScroll)
    }
  }, [location]) // Re-run effect when location changes
}
