import React, { useContext, useEffect } from 'react'

import ContentArea from 'Components/ContentArea'
import { RouterLinkTemplate } from 'Components'
import { RoundedButtonBlock, FooterBlock } from 'Blocks'
import LoginPopupMemberNonmemberFormBlock from 'Blocks/Forms/LoginPopupMemberNonmemberFormBlock.jsx'
import { AppContext } from '../contexts'
import { addWebPToImageURL } from 'Helpers/utilities'


const OfferPage = ({ data, inEditMode }) => {
  const { MainContentArea, FooterBlock, HeaderLogo, Heading, Subheading, CtaButton1, CtaButton2, FeaturedImage, FooterLogo,
    LoginPopupMemberNonmemberReference1, LoginPopupMemberNonmemberReference2 } = data
  const showLoginButton1Instead = CtaButton1?.Url === null
  const showLoginButton2Instead = CtaButton2?.Url === null

  const { appContext, setAppContext } = useContext(AppContext)
  useEffect(() => setAppContext({ ...appContext, header: null, footer: null }), [])

  return (
    <>
      <header className="container mx-auto max-w-screen-xl py-6 px-12">
        {(HeaderLogo || inEditMode) && <RouterLinkTemplate Path={'/'} inEditMode={inEditMode}>
          <img alt={HeaderLogo?.AltText} data-src={addWebPToImageURL(HeaderLogo?.Url)} width="300" height="70" className="lazy max-h-24" data-epi-edit="HeaderLogo" />
        </RouterLinkTemplate>}
      </header>
      <div className="container mx-auto max-w-screen-xl mb-6">
        <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6 grid md:grid-cols-12 items-center gap-6">
          <div className="md:col-span-7 grid gap-4">
            <h1 className="lg:text-6xl text-4xl font-medium font-display tracking-tightest" data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </h1>
            <h3 className="lg:mt-10 lg:text-2xl text-lg font-body tracking-tight" data-epi-edit="Subheading">
              {Subheading}
              {inEditMode && !Subheading && 'Click to edit Subheading'}
            </h3>
            <div className="hidden md:flex gap-4 pt-3">
              {showLoginButton1Instead ?
                LoginPopupMemberNonmemberReference1 && <LoginPopupMemberNonmemberFormBlock data={LoginPopupMemberNonmemberReference1} name='LoginPopupMemberNonmemberForm' inEditMode={inEditMode} />
                :
                <RoundedButtonBlock data={CtaButton1} name="CtaButton1" inEditMode={inEditMode} />
              }
              {showLoginButton2Instead ?
                LoginPopupMemberNonmemberReference2 && <LoginPopupMemberNonmemberFormBlock data={LoginPopupMemberNonmemberReference2} name='LoginPopupMemberNonmemberForm2' inEditMode={inEditMode} />
                :
                <RoundedButtonBlock data={CtaButton2} name="CtaButton2" inEditMode={inEditMode} />}
            </div>
          </div>
          <div className="md:col-span-5 grid justify-center">
            {(FeaturedImage || inEditMode) && <img alt={FeaturedImage?.AltText} src={addWebPToImageURL(FeaturedImage?.Url)} width="350" height="350" data-epi-edit="FeaturedImage" className="hidden md:block" />}
            {(FeaturedImage || inEditMode) && <img alt={FeaturedImage?.AltText} src={addWebPToImageURL(FeaturedImage?.Url)} width="200" height="200" data-epi-edit="FeaturedImage" className="md:hidden justify-self-center" />}
            <div className="md:hidden flex gap-4 pt-4 justify-between">
              <RoundedButtonBlock data={CtaButton1} name="CtaButton1" inEditMode={inEditMode} />
              <RoundedButtonBlock data={CtaButton2} name="CtaButton2" inEditMode={inEditMode} />
            </div>
          </div>
        </div>
      </div>
      <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
      <footer className="container mx-auto max-w-screen-xl pt-6 px-6">
        <div className="flex flex-col items-center">
          {(FooterLogo || inEditMode) && (
            <img
              alt={FooterLogo?.AltText}
              data-src={addWebPToImageURL(FooterLogo?.Url)}
              width="400"
              height="45"
              className="lazy mb-4"
              data-epi-edit="FooterLogo"
            />
          )}

          {(FooterBlock || inEditMode) && (
            <div className="w-full border-t border-gold mt-6 mb-4">
              <div className="flex flex-col items-center mt-6">
                {FooterBlock[0]?.NcuaImage && (
                  <img
                    alt={FooterBlock[0].NcuaImage?.AltTag}
                    data-src={addWebPToImageURL(FooterBlock[0].NcuaImage?.Url)}
                    width="400"
                    height="45"
                    className="lazy mb-4"
                    data-epi-edit="FooterBlock.Image"
                  />
                )}
                <div className="text-violet text-xs text-center">
                  <div>
                    Copyright &copy; {new Date().getFullYear()} Wellby Financial. All rights reserved.
                  </div>
                  <div
                    className="contents"
                    data-epi-edit="CopyrightText"
                    dangerouslySetInnerHTML={{ __html: data.FooterBlock[0].CopyrightText }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </footer>
    </>
  )
}

export default React.memo(OfferPage)
